<template>
  <section id="kona-tab" v-show="isActive">
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: 'KonaTab',
  props: {
    name: {
      type: String,
      required: true
    },
    icon: String,
    iconPack: {
      type: String,
      default: 'material'
    },
    infoTooltip: String,
    selected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false
    }
  },
  // computed: {
  //   href() {
  //     return '#' + this.name.toLowerCase().replace(/ /g, '-')
  //   }
  // },
  watch: {
    selected(newValue) {
      if (this.isActive !== newValue) {
        this.isActive = newValue
        if (this.isActive) {
          /** Bug found throwing error here this.$parent undefined */
          if (this.$parent && this.$parent.setAnimation !== undefined) {
            this.$parent.setAnimation(this.$parent.tabs.indexOf(this))
          }
        }
      }
    }
  },
  mounted() {
    this.isActive = this.selected
  }
}
</script>

<style lang="scss">
#kona-tab {
  margin-top: 20px;
}
</style>
